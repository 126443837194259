import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import useEffectLocation from '../hooks/useEffectLocation';

import './style.scss';

const Home = React.lazy(() => import('../views/home'));
const Showcase = React.lazy(() => import('../views/showcase'));
const Work = React.lazy(() => import('../views/Work'));
const ProjectDetails = React.lazy(() =>
  import('../views/portfolio/ProjectDetails')
);

const About = React.lazy(() => import('../views/About'));
const Contact = React.lazy(() => import('../views/Contact'));
const BlogDetails = React.lazy(() => import('../views/blog/BlogDetails'));
const NotFound = React.lazy(() => import('../views/404'));
const Router = () => {
  const [transPage, setTransPage] = useState('in');
  const scrollbar = useSelector((state) => state.scrollbar);

  const location = useEffectLocation((l) => {
    setTransPage('out');
  });


  const onAnimateEnd = () => {
    if (transPage !== 'out') return;
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
    setTransPage('in');
  };

  return (
    <div
      id='dsn-content'
      className={`dsn-transition-page dsn-animate-${transPage}`}
      onAnimationEnd={onAnimateEnd}
    >
      <Suspense fallback={<div className='background-main h-100-v' />}>
        <Routes location={location}>
          <Route path='/' element={<Home />} />
          <Route path='/portfolio' element={<Showcase />} />
          {/* <Route exact path='/portfolio' element={<Work />} /> */}
          <Route exact path='/portfolio/:slug' element={<ProjectDetails />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/blog/:id' element={<BlogDetails />} />
          <Route exact path='*' element={<NotFound/>} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(Router);
