import React from 'react';
import { dsnCN } from '../../../hooks/helper';

function MenuContent({ className }) {
  const socialData = [
    { link: 'https://dribbble.com/TroudiAhmed', name: 'Dribbble.' },
    { link: 'https://www.behance.net/ahmedtroudi', name: 'Behance.' },
    { link: 'https://www.linkedin.com/in/ahmed-troudi13', name: 'Linkedin.' },
    { link: 'https://twitter.com/TroudiAhmed', name: 'Twitter.' },
  ];
  return (
    <div
      className={dsnCN(
        'container-content  d-flex flex-column justify-content-center',
        className
      )}
    >
      <div className='nav__info'>
        <div className='nav-content'>
          <span className='sm-title-block mb-10'>Studio</span>
          <p>
            Menzah 7 Bis, Ariana <br /> 2037, Tunisia
          </p>
        </div>
        <div className='nav-content mt-30'>
          <span className='sm-title-block mb-10'>Contact</span>
          <p className='links over-hidden mb-1'>
            <a
              className='link-hover'
              href='tel:+216 29 390 807'
              data-hover-text='+216 29 390 807'
            >
              +216 29 390 807
            </a>
          </p>
          <p className='links over-hidden'>
            <a
              className='link-hover'
              href='mailto:contact@ahmedtroudi.com'
              data-hover-text='contact@ahmedtroudi.com'
            >
              contact@ahmedtroudi.com
            </a>
          </p>
        </div>
      </div>
      <div className='nav-social nav-content mt-30'>
        <div className='nav-social-inner p-relative'>
          <span className='sm-title-block mb-10'>Follow me</span>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
